/**
 * Type: ページ
 * What: アクセスページ
 */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import BreadcrumbComponent from '../components/Breadcrumb';
import Image from '../util/Image';
import { Button } from '../components/Btn';

const pageTitle = 'アクセス';
const pageDescription =
  'E・ZO FUKUOKA（イーゾ フクオカ）のアクセスと駐車場情報です。車や公共の交通機関をご利用の方等、参考にしてください（他サイト参考にもう少し文章精査します）';
const pageSlug = 'access';

// Data Props Template
const Template = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section bg-14">
      <div className="container">
        <div className="inner">
          <h3 className="headline">
            <span>マップ</span>
          </h3>
          <div className="tac mb10">
            <div className="border-image-box">
              <Image src="img-access01.png" />
            </div>
          </div>
          <div className="mt-5 tac">
            <div className="border-image-box google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.3792072662195!2d130.36156461557442!3d33.59546414910939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354193426ac0d0c1%3A0xe80ad351de56da8e!2sBOSS%20E%EF%BD%A5ZO%20FUKUOKA!5e0!3m2!1sja!2sjp!4v1647589108335!5m2!1sja!2sjp"
                width="720"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="GoogleMap"
              />
            </div>
          </div>
          <div className="content tac mt-5">
            <p>
              みずほPayPayドームから徒歩0分、ドームのデッキから本施設の3階に接続しています。
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section bg-14">
      <div className="container">
        <h3 className="headline anc" id="access">
          <span>アクセス</span>
        </h3>
        <h4 className="headline">
          <span>地下鉄</span>
        </h4>
        <div className="inner-slim">
          <div className="content">
            <div className="mb20">
              <Image src="img-access02.png" />
            </div>
            <h4 className="subtitle is-5">
              福岡市地下鉄の時刻、運賃、運行情報などのお問い合わせ先
            </h4>
            <p className="fwb">地下鉄お客様サービスセンター(各種案内)</p>
            <p>TEL：092-734-7800（8:00～20:00 年中無休）</p>
          </div>
        </div>
        <h4 className="headline">
          <span>バス</span>
        </h4>
        <div className="inner-slim">
          <div className="content">
            <div className="mb20">
              <Image src="img-access03.png" />
            </div>
            <p className="list-mark mb20">
              <span className="attention">※</span>
              公式戦やイベント開催日には直行臨時バスが運行されます。天神高速バスターミナル、博多バスターミナル6番、
              7番のりばよりご利用ください。また、一部の公式戦やイベント開催日には北九州・筑豊・久留米から臨時高速バスが運行されます。
            </p>
            <h4 className="subtitle is-5">
              西鉄バス・電車の時刻、運賃、運行情報などのお問い合わせ先
            </h4>
            <p className="fwb">西鉄お客さまセンター</p>
            <p>
              TEL：0570-00-1010（8:00～20:00
              年中無休）※PHS、IP電話からは092-303-3333
            </p>
          </div>
        </div>

        <h4 className="headline">
          <span>車</span>
        </h4>
        <div className="inner-slim">
          <div className="content">
            <Image
              src="img-access04.png"
              alt="西公園ランプより約5分、百道ランプより約5分"
            />
          </div>
        </div>

        <h3 className="headline anc" id="parking">
          <span>駐車場</span>
        </h3>
        <div className="inner-slim">
          <div className="btn-wrap">
            <a
              href="https://www.softbankhawks.co.jp/stadium/parking/"
              className="btn-regular wide"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>詳しくはこちらををご覧ください</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </a>
          </div>
          {/* <h4 className="title is-5">緊急事態宣言期間中におけるみずほPayPayドーム駐車場ご利用料金について</h4>
          <div className="table-base mb60">
            <table className="inner-table mb30">
              <tr>
                <th>&nbsp;</th>
                <th>野球日（1日）</th>
                <th>コンサート日（1日）</th>
                <th>左記以外</th>
              </tr>
              <tr>
                <th className="has-text-align-center">料金制度</th>
                <td className="has-text-align-center">時間料金制</td>
                <td className="has-text-align-center">定額制</td>
                <td className="has-text-align-center">時間料金制</td>
              </tr>
              <tr>
                <th className="has-text-align-center">普通車</th>
                <td className="has-text-align-center">最初の1時間300円、<br />以降30分毎に150円ずつ加算</td>
                <td className="has-text-align-center">3,000円</td>
                <td className="has-text-align-center">最初の1時間300円、<br />以降30分毎に150円ずつ加算</td>
              </tr>
              <tr>
                <th className="has-text-align-center" rowSpan="2">割引・返金について</th>
                <td className="has-text-align-center">
                  <p>ドーム、E・ZO各店舗で1円以上ご利用（飲食・コンテンツ・購入）で下記割引になります。</p>
                  <p>平日：5時間まで無料<br />土日祝：3時間まで無料</p>
                </td>
                <td className="has-text-align-center">
                  <p>E・ZOご利用の金額に応じて返金対応を致します。</p>
                  <p>2,000円以上ご利用1,000円返金<br />4,000円以上ご利用2,000円返金<br />6,000円以上ご利用3,000円返金</p>
                </td>
                <td className="has-text-align-center">
                  <p>ドーム、E・ZO各店舗で1円以上ご利用（飲食・コンテンツ・購入）で下記割引になります。</p>
                  <p>平日：5時間まで無料<br />土日祝：3時間まで無料</p>
                </td>
              </tr>
              <tr>
                <td className="has-text-align-left">各店舗ご利用時に駐車券をご提示ください。<br />（割引対応できない店舗がございます。レシート持参の上、E・ZO3階インフォメーションにへお越しください。）</td>
                <td className="has-text-align-left">駐車場料金所にて配備の『入金証明書』とレシートをご持参の上、E・ZO3階インフォメーションにへお越しください。</td>
                <td className="has-text-align-left">各店舗ご利用時に駐車券をご提示ください。<br />（割引対応できない店舗がございます。レシート持参の上、E・ZO3階インフォメーションにへお越しください。）</td>
              </tr>
            </table>
            <table className="inner-table">
              <tr>
                <th>&nbsp;</th>
                <th>野球日（1日）</th>
                <th>コンサート日（1日）</th>
                <th>左記以外</th>
              </tr>
              <tr>
                <th className="has-text-align-center">大型車<br />（車高2.1m以上）</th>
                <td className="has-text-align-center" colSpan="3">5,000円（事前予約制）</td>
              </tr>
            </table>
            <p className="list-mark">
              <span>※</span>
              大型車につきましては、割引・返金対象外となります。
            </p>
          </div>
          <p>
            みずほPayPayドーム福岡駐車場に駐車いただけます。
            <br />
            みずほPayPayドーム駐車場の空室情報はこちらからご確認ください。
          </p>
          <p className="list-mark mb-4">
            <span className="attention fwb is-size-7">
              ※試合日については満車により入庫できない場合がございます。
              <br className="pc" />
              また、出庫については状況により1時間以上かかる場合もございますのでご注意ください。
            </span>
          </p>
          <div className="btn-wrap mb-5">
            <Button
              href="https://www.softbankhawks.co.jp/stadium/parking/"
              target="_blank"
              rel="noopener noreferrer"
              className="wide"
            >
              みずほPayPayドーム福岡駐車場空室情報
            </Button>
          </div>
          <div className="table-base">
            <table>
              <tbody>
                <tr>
                  <th>場所</th>
                  <td>福岡市中央区地行浜2-2-2</td>
                </tr>
                <tr>
                  <th>収容人数</th>
                  <td>約1700台</td>
                </tr>
                <tr>
                  <th>料金</th>
                  <td>
                    <article>
                      <div className="btn-group left mb-4">
                        <Link to="/information/" className="btn-text" target="_blank" rel="noopener noreferrer">
                          <span>駐車場サービスについてはこちら</span>
                        </Link>
                      </div>
                      <table className="inner-table">
                        <tr>
                          <th>&nbsp;</th>
                          <th>野球・コンサート開催日（1日）</th>
                          <th>通常</th>
                        </tr>
                        <tr>
                          <td className="has-text-align-center">普通車</td>
                          <td className="has-text-align-left">3,000円</td>
                          <td className="has-text-align-left">
                            最初の1時間300円、
                            <br />
                            以降30分毎150円ずつ加算
                          </td>
                        </tr>
                      </table>
                      <p className="list-mark">
                        <span>※</span>
                        車高2.1m/車幅2.1m/車長5.1mを超える車両は『大型車』となりますので大型車駐車場（要予約）をご利用ください。
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        一度出庫された後の再入庫はできません。
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        イベントやコンサート時の一般の方の予約は承っておりません。
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        イベントにより駐車場料金が異なる場合がございます。
                      </p>
                    </article>
                    <article>
                      <h4 className="title is-size-4">割引について</h4>
                      <p>各店舗をご利用（飲食・コンテンツ・購入）で下記割引になります。</p>
                      <p className="mb0 is-size-6">
                        平日 :
                        {' '}
                        <b>5時間まで無料</b>
                      </p>
                      <p className="is-size-6 mb-4">
                        土日祝 :
                        {' '}
                        <b>3時間まで無料</b>
                      </p>
                      <p className="mb-0">【野球・コンサート開催日】</p>
                      <p>
                        E・ZOご利用の金額に応じて返金対応を致します。
                        <br />
                        駐車場にて配備の『入金証明書』ご持参の上、E・ZO3階インフォメーションにへお越しください。
                      </p>
                      <p className="mb0 is-size-6">
                        <b>2,000円以上ご利用 1,000円返金</b>
                      </p>
                      <p className="mb0 is-size-6">
                        <b>4,000円以上ご利用 2,000円返金</b>
                      </p>
                      <p className="mb-4 is-size-6">
                        <b>6,000円以上ご利用 3,000円返金</b>
                      </p>
                      <p className="mb-0">＜BOSS E・ZO FUKUOKA 3F information営業時間＞</p>
                      <p>
                        デーゲーム：20:00まで ナイトゲーム：23:00まで
                      </p>
                      <p className="list-mark mb0">
                        <span>※</span>
                        試合日以外のドームイベント開催日は20:00まで
                      </p>
                      <p className="list-mark mb0">
                        <span>※</span>
                        営業時間は予告なく変更になる場合がございます
                      </p>
                      <p className="list-mark mb0">
                        <span className="attention has-text-weight-bold">※営業時間終了後の返金対応はできかねますので早めにお手続きをお済ませください</span>
                      </p>
                    </article>
                    <article>
                      <h4 className="title is-size-4">事前予約について</h4>
                      <h5 className="ttl-strip">普通車の予約について</h5>
                      <div className="btn-group left mb10">
                        <a href="https://www.softbankhawks.co.jp/stadium/parking_service.html" className="btn-text" target="_blank" rel="noopener noreferrer">
                          <span>野球開催日の予約はこちら</span>
                        </a>
                      </div>
                      <p className="list-mark">
                        <span className="attention">※OP戦、イベントやコンサート時の予約は承っておりません。</span>
                      </p>
                    </article>
                  </td>
                </tr>
                <tr>
                  <th>営業時間</th>
                  <td>
                    <p>
                      平日 9:00～24:00
                      <br />
                      土・日・祝 8:00～24:00
                    </p>
                    <p className="list-mark">
                      <span>※</span>
                      留置き不可
                    </p>
                    <p className="list-mark">
                      <span>※</span>
                      通常営業時の入庫は23:30まで
                    </p>
                    <p className="list-mark">
                      <span>※</span>
                      野球・コンサート開催日は満車が予想されるため、入庫できない可能性があります
                    </p>
                    <p className="list-mark">
                      <span>※</span>
                      野球開催時、駐車予約券での入庫は6回終了までとなっております
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <article>
            <h4 className="title is-5">ご来場時の注意事項</h4>
            <p>
              みずほPayPayドーム福岡では衛生上の理由から飲食物の持ち込み、ペットを連れてのご来場をご遠慮いただいております。また安全上の理由から「ビン類」「缶類」の持ち込みもお断りしています。
              また、イベント会場の内外で、お客様ご自身の肖像、声が撮影される場合があります。詳細をご確認のうえ、ご来場ください。 ご協力をよろしくお願いいたします。
            </p>
          </article> */}
        </div>
      </div>
    </section>
    {/* <section className="section bg-14">
      <div className="container">
        <h3 className="headline anc" id="group">
          <span>団体・大型車の予約について</span>
        </h3>
        <div className="inner-slim">
          <article>
            <h4 className="ttl-strip">大型車の予約について</h4>
            <p>
              大型車は台数に限りがあるため事前の予約が必要です。
            </p>
            <div className="btn-wrap mb-2">
              <Button
                href="/files/yoyaku.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="wide"
              >
                【野球開催日以外】大型車の申込書はこちら
              </Button>
            </div>
            <p className="list-mark">
              <span>※</span>
              ご不明点がある場合は、下記へお問い合わせください。
            </p>
          </article>
          <article>
            <h4 className="ttl-strip">野球開催日以外のお問い合わせ先：ドームツアー予約係</h4>
            <div className="mb-2">
              <p className="is-size-5"><b>mail：&#105;&#110;&#102;&#111;&#45;&#100;&#111;&#109;&#101;&#116;&#111;&#117;&#114;&#64;&#115;&#111;&#102;&#116;&#98;&#97;&#110;&#107;&#104;&#97;&#119;&#107;&#115;&#46;&#99;&#111;&#46;&#106;&#112;</b></p>
              <p className="list-mark">
                ※営業時間: 10:00～11:30 12:30～17:00
              </p>
              <p className="list-mark">
                ※17:00以降に受診したメールにつきましては、返信が翌日以降になる場合がございますので、予めご了承ください。
              </p>
            </div>
            <div className="btn-wrap mb-2">
              <Button
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="wide grey-out"
              >
                【野球開催日】大型車の申し込みはこちら
              </Button>
            </div>
            <p>■試合日に併せて段階的に受付いたします。<br className="sp" /><span className="attention">10月以降の受付につきましては詳細決定後ご案内いたします。</span></p>
            <p>■そのほか試合開催日のバス運用詳細につきましては、団体観戦プランページの 「<a href="https://www.softbankhawks.co.jp/ticket/group/busparking.html" className="tx-link" target="_blank" rel="noopener noreferrer">バス駐車場</a>」をご確認ください。</p>
            <p className="list-mark">
              <span>※</span>
              ご不明点がある場合は、下記へお問い合わせください。
            </p>
          </article>
          <article>
            <h4 className="ttl-strip">野球開催日のお問合せ先：団体予約係</h4>
            <div>
              <p className="is-size-5"><b>mail：&#115;&#98;&#104;&#109;&#45;&#100;&#97;&#110;&#116;&#97;&#105;&#121;&#111;&#121;&#97;&#107;&#117;&#64;&#115;&#111;&#102;&#116;&#98;&#97;&#110;&#107;&#104;&#97;&#119;&#107;&#115;&#46;&#99;&#111;&#46;&#106;&#112;</b></p>
              <p>
                ※営業時間：10:00～17:00&nbsp;土日祝日休業
                <br />
                ※17:00以降に受信したメールにつきましては、返信が翌日以降になる場合がございますので、予めご了承ください。
              </p>
            </div>
          </article>
        </div>
      </div>
    </section> */}
  </Layout>
);

export default Template;
